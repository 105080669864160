import { useState, useEffect, useRef  } from "react";
import { useMatch, Link  } from "react-router-dom";
import Fade from 'react-reveal/Fade';
import './Products.css';

import mujerSeñalando from '../../img/imageGeneral/mujerSeñalando.jpg';
import errorimagen from '../../img/Logos/errorimagen.png';

import ProductsList from './ProductsList';

const Products = () => {
    
    const [dataCategory, setDataCategory] = useState([]);
    const [data, getData] = useState();
    const [productSearch, setProduSearch] = useState();
    const [CantResults, setCantResults] = useState(0);
    let topRef = useRef(null);
 
    const match = useMatch({
        path: "/productos/:slug",
        strict: true,
        sensitive: true
    });

    const getCategorys = () => {
        fetch('https://proveedoramys.com.co/api/getallcategorias')
        .then(response => response.json())
        .then(data => setDataCategory(data))
        .catch(err => console.error(err))
    }

    useEffect(() => {
        getCategorys();

        if(match.params.slug !== 'todos') {
            showForCategory(match.params.slug.slice(1))
        }
    },[]);

    const getCantResults = (value) => {
        setCantResults(value)
    }

    const search = () => {
        setProduSearch(document.getElementById('inputSearch').value);
        getData(undefined);
    }

    const showForCategory = async (category) => {

        if(category !== "") {
            const response = await fetch(`https://proveedoramys.com.co/api/searchCategory/${category}`)
            const json = await response.json();
            getData(json);
            getCantResults(json.length);
            setProduSearch()
        } else {
            getData(undefined);
        }
    }


    return (
        <div>
            <div className="sectionSearch" id='top-product'>
                <section className="search">
                    <input id="inputSearch" type="text" name="search" placeholder="Buscar Producto" autocomplete="off"/>
                    <span className="icon-search" onClick={() => search()}></span>
                </section>
            </div>

            <section className="categorysProd">
                <div className="boxSelect">
                    <select id="selectCategory" className="selectCategory" onChange={() => showForCategory(document.getElementById('selectCategory').value)}>
                        <option value="">Selecciona una categoria</option>
                        {dataCategory.map((value) => {
                            return <option value={value.CODCATEGORIA}>{value.CATEGORIA}</option>
                        })}
                    </select>
                </div>
                <p>Se encontraron <span>{CantResults}</span> resultados</p>
            </section>

            <hr className='hrtitleProduts'/>
                {
                    (data !== undefined) ?
                    <article className="productsContainer">
                        {data.map((value) => {
                            return <div key={value.ID} className="cardProduct">
                                <img src={errorimagen} alt="error imagen proveedora"></img>
                                <section className="sectionCardInfo">
                                    <p>{value.NOMBREPROD.toLowerCase()}</p>
                                    <a href={`https://wa.me/+573134531530/?text=Hola ProveedoraMyS, me gustaria averiguar la disponibilidad de este producto -> ${value.NOMBREPROD.toLowerCase()}`} target="_blank">
                                        <button>Cotiza disponibilidad del producto <span className="icon-whatsapp"></span></button>
                                    </a>
                                </section>
                            </div>
                        })}
                    </article>
                    :
                    <ProductsList getCantResults={getCantResults} productSearch={productSearch} errorimagen={errorimagen} topRef={topRef}/>
                }

            <section className="sectioninfomore">
                <div className="columnInfo">
                    <h3>En Proveedora M y S encontraras todo lo que necesitas</h3>
                    <p>No dudes en comunicarte con nosotros para brindarte la mejor asesoria y poder conocer todo lo que tenemos para ti</p>
                    <Link to="/contacto" className='menuOption' onClick={() => { topRef.current.scrollIntoView(true) }}>
                        <button>contactanos</button>
                    </Link>
                </div>
                <Fade  right>
                    <img src={mujerSeñalando} alt="proveedores"></img>
                </Fade >
            </section>
            
        </div>
    );
};

export default Products;