import { Link } from 'react-router-dom';
import { useRef } from 'react';

import './Footer.css';

import LogoFooter from '../../img/Logos/logos-02.png';

const Footer = () => {
    let topRef = useRef(null);

    const activeBtns = () => {
        topRef.current.scrollIntoView(true);
    }

    return (
        <footer>
            <section className='containerFooter'>
                <img src={LogoFooter} alt='logoProveedora'></img>
                <section className='containerFooterColumn'>
                    <article className='columnFooter'>
                        <h3>menu</h3>
                        <ul>
                            <Link to="/" className='menuOption' onClick={() => activeBtns() }><li>inicio</li></Link>
                            <Link to="/productos/todos" className='menuOption'  onClick={() => activeBtns() }><li>productos</li></Link>
                            <Link to="/quienesSomos" className='menuOption'  onClick={() => activeBtns() }><li>nosotros</li></Link>
                            <Link to="/contacto" className='menuOption'  onClick={() => activeBtns() }><li>contacto</li></Link>
                        </ul>
                    </article>
                </section>
                <article className='columnFooterContact'>
                    <div>
                        <h3><span className='icon-mail-alt'></span>correo</h3>
                        <p>briancanonceo@proveedoramys.com</p>
                        <p>proveedoramys@proveedoramys.com</p>
                    </div>
                    <div>
                        <h3><span className='icon-phone'></span>telefono</h3>
                        <p>360-59-16</p>
                        <p>313-453-15-30</p>
                    </div>

                    <div>
                        <h3><span className='icon-clock'></span>Horarios de atencion</h3>
                        <p>Lunes a viernes de 8 AM - 5 PM</p>
                    </div>
                </article>
            </section>

            <section className='containerSocialMedia'>
                <a className='socialMedia' href='https://www.google.com' target='_black'><span className="icon-facebook-official"></span></a>
                <a className='socialMedia' href='https://www.google.com' target='_black'><span className="icon-instagram"></span></a>
            </section>

            <section className='copyRigth'>
                <p>© Proveedora de Materiaes y Servicios LTDA - SG TECNOLOGY</p>
            </section>
        </footer>
    );
};

export default Footer;