import './Contact.css';
import React, { useState, useRef } from 'react';
import emailjs from '@emailjs/browser';
import LightSpeed from 'react-reveal/LightSpeed';

import contactoImg from '../../img/imageGeneral/contactoImg.jpg';

const Contact = () => {

    const form = useRef();
    const [alertStatus, setAlertStatus] = useState(false);
    const [alertMessage, setalertMessage] = useState();
    
    function sendEmail(e) {
        e.preventDefault();

        emailjs.sendForm('service_qmrj92c','template_hmasc9g',form.current,'Hk4a8FuwgBjq998P4')
        .then((result) => {
            if(result.status === 200) {
                setAlertStatus(true);
                setalertMessage('Mensaje enviado con exito');
            } else {
                setAlertStatus(false);
                setalertMessage('No se pudo enviar el mensaje');
            }  
        }, (error) => {
            console.log(error);
        });
        e.target.reset();

        setTimeout(function () {
            setAlertStatus(false);
        },4000)
    }

    const showInputs = (event) => {

        if(Event.value.lenght >= 1) {
            Event.parentElement.classList.add('hasInput');
        } else {
            Event.parentElement.classList.remove('hasInput');
        }
    }

    return (
        <div>
            <section className='containerFormImage'>
                <img className='imagenForm' src={contactoImg} alt='imagen contacto proveedoramys'></img>

                {alertStatus ? 
                    <section className="alertForm">
                        <h1>{alertMessage}</h1>
                        <span className="icon-forward"></span>
                    </section>
                    :
                    null
                }

                <form className='form_container' ref={form} onSubmit={sendEmail}>
                    <h2>Formulario de contacto</h2>

                    <div className='input_container'>
                        <input className='inputContact' type='text' name='name_Client' oninput={ () => showInputs()} autoComplete='off' spellCheck='false' required/>
                        <label className='labelContact'>Nombre</label> 
                    </div>

                    <div className='input_container'>
                        <input className='inputContact' type='text' name='email_Client' oninput={ () => showInputs()} autoComplete='off' spellCheck='false' required/>
                        <label className='labelContact'>Correo</label> 
                    </div>

                    <div className='input_container'>
                        <input className='inputContact' type='text' name='tel_Client' oninput={ () => showInputs()} autoComplete='off' spellCheck='false' required/>
                        <label className='labelContact'>Numero Telefonico</label> 
                    </div>

                    <div className='input_container'>
                        <input className='inputContact' type='text' name='message_Client' oninput={ () => showInputs()} autoComplete='off' spellCheck='false' required/>
                        <label className='labelContact'>Mensaje</label> 
                    </div>

                    <div className='boxBtonSendMessage'>
                        <input className='btnSendMessage' type='submit' value='Enviar Mensaje' />
                    </div>
                </form>
            </section>

            <h3 className='titleInfoContact'>Informacion de contacto</h3>
            <hr className='hrContact'/>

            <section className='containerInfoContact'>
                <article>
                    <h4><span className='icon-clock'></span>telefono</h4>
                    <p>360-59-16</p>
                    <p>313-453-15-30</p>
                </article>

                <article>
                    <h4><span className='icon-clock'></span>correo</h4>
                    <p>briancanonceo@proveedoramys.com.co</p>
                    <p>proveedoramys@proveedoramys.com.co</p>
                </article>

                <article>
                    <h4><span className='icon-location'></span>Direccion</h4>
                    <p>Calle 12 No. 21-87. Bogotá</p>
                </article>
            </section>

            <LightSpeed left>
                <p className='textContact'>¡No esperes más para tener las herramientas que necesitas para tu próximo proyecto! Contáctanos ahora y te ayudaremos a encontrar todo lo que necesitas en nuestra amplia selección de equipo de ferretería de alta calidad.</p>
            </LightSpeed>
       </div>
    );
};

export default Contact;