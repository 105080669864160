import { useEffect, useState } from "react";

const ProductsList = ({getCantResults,productSearch,errorimagen,topRef}) => {

    const [dataProducts, setDataProducts] = useState();
    const [data, getData] = useState();
    const [cantPageinital, getPagenPageinital] = useState(0);
    const [cantPage, getPagenPage] = useState(15);
    const [count, getCount] = useState(1);

    useEffect(() => {
        if(productSearch !== undefined && productSearch !== '') {
            searchProduct()
            .catch(console.error);
        } else {
            // call the function
           fetchData()
           .catch(console.error);
   
           if (dataProducts !== undefined) {
               getFilter();
           }
        }
    },[productSearch, dataProducts])


    // get all product when enter the url
    const fetchData = async () => {
        const response = await fetch('https://proveedoramys.com.co/api/getallproducts')
        const json = await response.json();
        setDataProducts(json);
    }

    // search the products
    const searchProduct = async () => {
        const response = await fetch(`https://proveedoramys.com.co/api/searchProduct/${productSearch}`)
        const json = await response.json();
        getData(json);
        getCantResults(json.length);
    }

    const getFilter = () => {
        const filter = dataProducts.slice(cantPageinital,cantPage);
        getData(filter);
        getCantResults(dataProducts.length);
    }

    const nextPage = () => {
        if (cantPage < dataProducts.length) {   
            getPagenPageinital(cantPageinital + 15);
            getPagenPage(cantPage + 15);
            getCount(count + 1)
        }
    }

    const backPage = () => {
        if (cantPage !== 20)  {
            getPagenPageinital(cantPageinital - 15);
            getPagenPage(cantPage - 15);
            getCount(count - 1)
        }
    }
    
    return (
        <>
            <article className="productsContainer">
                { (data !== undefined) ?
                        data.map((value) => {
                        return <div key={value.ID} className="cardProduct">
                            <img src={errorimagen} alt="error imagen proveedora"></img>
                            <section className="sectionCardInfo">
                                <p>{value.NOMBREPROD.toLowerCase()}</p>
                                <a href={`https://wa.me/+573134531530/?text=Hola ProveedoraMyS, me gustaria averiguar la disponibilidad de este producto -> ${value.NOMBREPROD.toLowerCase()}`} target="_blank">
                                    <button>Cotiza disponibilidad del producto <span className="icon-whatsapp"></span></button>
                                </a>
                            </section>
                        </div>

                    })
                    :
                    <p>Buscando productos</p>
                }
            </article>

            <article className="boxBtnsPage">
                    <p>{count}</p>
                    <a href="#top-product"><button onClick={() => backPage()}><span className="icon-left-open"></span></button></a>
                    <a href="#top-product"><button onClick={() => nextPage()}><span className="icon-right-open"></span></button></a>
            </article>
        </>
    );
};

export default ProductsList;