import { useRef, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import './App.css';
import "./fontello/css/fontello.css"

import logo from './img/Logos/LOGOLETRA-01.png';

// Componentes
import Home from './Components/Home/Home';
import AboutUs from './Components/aboutUs/AboutUs';
import Contact from './Components/Contact/Contact';
import Footer from './Components/Footer/Footer';
import Products from './Components/Products/Products';

function App() {

  let topRef = useRef(null);

  const [isResponsive , setIsResponsive] = useState(false);

  const activeBtns = () => {
    topRef.current.scrollIntoView(true);
    setIsResponsive(false);
  }

  return (
    <div className="App" id='top-menu'>
      <Router>
        <button className="icon-menu" onClick={() => setIsResponsive(!isResponsive)}></button>
        <nav className={isResponsive ? "menuResponsive" : ""}>
          <img className='logo' src={logo} alt='logoProveedoraMys'></img>
          <ul>
            <Link to="/" className='menuOption' onClick={() => activeBtns() }><li>inicio</li></Link>
            <Link to="/productos/todos" className='menuOption'  onClick={() => activeBtns() }><li>productos</li></Link>
            <Link to="/quienesSomos" className='menuOption'  onClick={() => activeBtns() }><li>Nosotros</li></Link>
            <Link to="/contacto" className='menuOption'  onClick={() => activeBtns() }><li>contacto</li></Link>
          </ul>
        </nav>

        <article className="socialLateral">
          <section className="socialItemLateral">
            <a className="fondoItemSocial" href="" target="_blank"><span className="icon-facebook-official"></span></a>
          </section>

          <section className="socialItemLateral">
            <a className="fondoItemSocial" href="" target="_blank"><span className="icon-instagram"></span></a>
          </section>
        </article>

        <a href="https://wa.me/+573134531530/?text=Hola ProveedoraMyS, Quiero obtener mas informacion acerca de un producto" target="_blank"><button className="btnTop icon-whatsapp"></button></a>

        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/productos/:slug' element={<Products />} />
          <Route path='/quienesSomos' element={<AboutUs />} />
          <Route path='/contacto' element={<Contact />} />
          <Route path="*" element={<Home />} />  { /* ruta 404 */ } 
        </Routes>
        <Footer />
      </Router>

    </div>
  );
}

export default App;
