import { Link } from 'react-router-dom';
import { useRef } from 'react';
import './AboutUs.css';
import Zoom from 'react-reveal/Zoom';

import edificioImagen from '../../img/imageGeneral/imagne.png';

const AboutUs = () => {
    let topRef = useRef(null);

    return (
        <div>
            <section className='containerInfoAboutUs'>
                <article>
                    <img className='buildingAboutUs' src={edificioImagen} alt='imagen edificio proveedrora'></img>
                    <Link to="/contacto" className='menuOption' onClick={() => { topRef.current.scrollIntoView(true) }}><button className='btnContactAbout'>Contactar</button></Link>
                </article>
                <article className='sectionInfo'>
                    <h2>¿quienes somos?</h2>
                    <p>Somos una empresa con experiencia en la comercialización de productos de ferretería de primera calidad; dada nuestra política flexible en precios justos, excelentes inventarios, en el constante apoyo a nuestros clientes y ágiles entregas en cualquier parte del país, pretendemos fortalecer dicho mercado y posicionarnos en el sector</p>

                    <p>Con el ánimo de brindar una atención personalizada y especializada a su empresa, queremos poner a su disposición nuestro portafolio de productos entre los que se destacan Herramientas manuales y eléctricas, Seguridad industrial, Soldaduras, Tuberías y accesorios para vapor, Láminas y rollos, Platinas y ángulos, Materiales para construcción, Pinturas, Eléctricos y Ferretería en general</p>
                </article>
            </section>

            <article className='containerVision'>
                <h3>Visión</h3>
                <p>Nuestra visión es ser reconocidos como el referente de excelencia y confianza en el suministro de materiales y servicios de construcción. Aspiramos a ser el socio estratégico preferido de nuestros clientes, brindándoles soluciones completas y a medida para sus proyectos. Nos esforzamos por establecer relaciones a largo plazo con nuestros clientes, basadas en la transparencia, la colaboración y la satisfacción mutua. Buscamos ser líderes en la adopción de tecnologías vanguardistas y prácticas sostenibles, con el objetivo de impulsar el progreso de la industria de la construcción y contribuir al desarrollo sostenible de las comunidades en las que operamos.</p>
            </article>

            <article className='containermission'>
                <h3>Mision</h3>
                <p>Nuestra misión es ser el proveedor líder de materiales y servicios de construcción, brindando soluciones integrales y de alta calidad a nuestros clientes. Nos comprometemos a suministrar productos confiables, duraderos y sostenibles, así como a ofrecer servicios excepcionales que satisfagan las necesidades y expectativas de nuestros clientes en cada proyecto. Buscamos contribuir al desarrollo y crecimiento de la industria de la construcción, impulsando la innovación, la eficiencia y la excelencia en todos nuestros procesos</p>
            </article>

            <section className='targetsInfo'>
                 <Zoom>
                    <article>
                        <h3 className='titleTargetInfo'>Nuestra Compañia</h3>
                        <p className='infoTarget'>En nuestra empresa, nos enorgullece nuestra dedicación a la excelencia y la satisfacción del cliente. Trabajamos en estrecha colaboración con nuestros clientes, entendiendo sus necesidades y ofreciendo soluciones personalizadas que se ajusten a sus objetivos y presupuestos</p>
                    </article>
                </Zoom>

                 <Zoom>
                    <article>
                        <h3 className='titleTargetInfo'>Nuestro equipo</h3>
                        <p className='infoTarget'>Nuestro equipo está compuesto por profesionales altamente capacitados y apasionados por la industria de la construcción. Cada miembro de nuestro equipo aporta una amplia experiencia y conocimientos especializados en su campo, lo que nos permite ofrecer un servicio integral y de alta calidad a nuestros clientes</p>
                    </article>
                </Zoom>

                 <Zoom>
                    <article>
                        <h3 className='titleTargetInfo'>Nuestra labor</h3>
                        <p className='infoTarget'>Nuestra labor como empresa proveedora de materiales y servicios de construcción es fundamental en la industria de la construcción. Nos dedicamos a ofrecer soluciones integrales y de alta calidad para satisfacer las necesidades de nuestros clientes y contribuir al éxito de sus proyectos</p>
                    </article>
                </Zoom>
            </section>

            <section className="sectionImageInfo">
                <article className="backgroundImage">
                    <p>Para PROVEEDORA DE MATERIALES Y SERVICIOS LTDA, es de gran importancia contar
                    con clientes como ustedes y tener el placer de atender sus requerimientos diarios, es
                    por esto que brindamos un amplio portafolio de productos, relacionado a
                    continuación. </p> 
                    <Link to="/productos/todos" className='menuOption' onClick={() => { topRef.current.scrollIntoView(true) }}>
                        <button className="btnLinkProductsInfo"><span className="icon-right-hand"></span>Ver productos</button>
                    </Link>
                </article>
            </section>

            <section className='containerMoreInfo'>
                <article className='articleMoreInfo'>
                    <span className='icon-cc-visa'></span>
                    <div>
                        <h4>Metodos de Pago</h4>
                        <p>Recibimos todas las tarjetas,pagos en efectivo y transferencias</p>
                    </div>
                </article>

                <hr />

                <article className='articleMoreInfo'>
                    <span className='icon-users'></span>
                    <div>
                        <h4>Equipo Especializado</h4>
                        <p>Nos enfocamos en atenderte de la mejor manera</p>
                    </div>
                </article>

                <hr />

                <article className='articleMoreInfo'>
                    <span className='icon-clock'></span>
                    <div>
                        <h4>Tiempo de Respuesta</h4>
                        <p>Nuestra atención siempre puesta a los clientes</p>
                    </div>
                </article>
            </section>
       </div>
    );
};

export default AboutUs;