import { Link } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';

import './Home.css';
import Zoom from 'react-reveal/Zoom'; //transiciones

import grua from '../../img/imageGeneral/grua.png';
import edificios from '../../img/imageGeneral/edificios.png';
import edificio from '../../img/imageGeneral/edificio.png';
import handToll from '../../img/imageGeneral/handToll.png';
import imagenHome1 from '../../img/imageGeneral/imagenhome1.jpg';
import imagenHome2 from '../../img/imageGeneral/imagenhome2.jpg';
import imagenHome3 from '../../img/imageGeneral/imagenhome3.jpg';

import Carousel from './carousel.js';
import Brands from '../brands/Brands';

const Home = () => {

    let topRef = useRef(null);
    
    const [words , setWords] = useState();
    const [count , setCount] = useState(0);
    let arrayWords = ['taladros', 'cascos', 'tornillos', 'herramientas', 'y mucho mas'];

    const showWord = (count) => {

        let arrayWordsSplit = arrayWords[count].split('');
        let i = 0;
        let newArray = "";
        let interval = setInterval(function () {

            newArray += arrayWordsSplit[i];
            setWords(newArray);
            i++;

            if (i === arrayWordsSplit.length) {
                clearInterval(interval);
            }
        }, 85);
    };
 
    useEffect(() => {
        setTimeout(() => {
            setCount(count+1)

            if (count < arrayWords.length) {
                showWord(count);
            } else {
                setCount(0)
            }

        },3000)
    },[count])


    return (
        <div>
            <div className="containerHome">
                <h1>PROVEEDORA DE MATERIALES Y SERVICIOS LTDA</h1>

                <p className='textDescriptionHome'>Encuentra aqui todo lo mejor en <span>{words}</span></p> 
            
                <img className='grua' src={grua} title='grua'></img>
                <img className='edificio' src={edificio} title='edificio'></img>
                <img className='edificios' src={edificios} title='edificios'></img>
            </div>

            <section className='containerInfoPreview'>
                <img className='' src={handToll} title='handWhitTools'></img>

                <article className='boxInfoPreview'>
                    <h2>¿QUE PODEMOS OFRECERTE?</h2>
                    <p> Bienvenidos a nuestra proveedora M y S, Nos dedicamos a proveer una amplia variedad de herramientas y suministros de calidad a nuestros clientes, desde herramientas manuales hasta equipos eléctricos, nuestra empresa tiene todo lo que necesita para realizar cualquier proyecto de construcción, renovación o mantenimiento.</p>
                    <div className="btnContactPreview">
                        <Link to="/productos/todos" className='menuOption' onClick={() => { topRef.current.scrollIntoView(true) }}><button>Ver Productos</button></Link>
                    </div>
                </article>
            </section>

            <article className="containerCategory">
                <h3 className='titleCategory'>categoria</h3>
                <hr className='hrtitle'/>
                <Carousel />
            </article>
            
            <section className='paragrafInfoHome'>
                <p>Somos una empresa con experiencia en la comercialización de productos de ferretería de primera calidad</p>
                <Link to="/quienesSomos" className='menuOption' onClick={() => { topRef.current.scrollIntoView(true) }}><button className='btnKnowMore'>conocer mas <span className='icon-left-hand'></span></button></Link>
            </section>

            <section>
                <Zoom left cascade>
                    <article className="sectionImage1">
                        <div className="cards">
                            <img src={imagenHome1} alt="cardsHome" />
                            <div className="infoCard">
                                <p>NUESTROS PRODUCTOS ESTAN RESPALDADOS POR LAS MEJORES MARCAS EN EL AMBITO NACIONAL</p>
                            </div>
                        </div>
                    </article>
                </Zoom>

                <Zoom left cascade>
                    <article className="sectionImage2">
                        <div className="cards">
                            <img src={imagenHome2} alt="cardsHome" />
                            <div className="infoCard">
                                <p>ENCUENTRA EN PROVEEDORA MYS LOS MEJORES PRODUTOS PARA TU SIGUIENTE PROYECTO, CONSTRUYE IDEAS CON CALIDA</p>
                            </div>
                        </div>
                    </article>
                </Zoom>

                <Zoom rigth cascade>
                    <article className="sectionImage3">
                        <div className="cards">
                            <img src={imagenHome3} alt="cardsHome" />
                            <div className="infoCard">
                                <p>PRODUCTOS DE PRIMERA CALIDAD Y 100% GARANTIZADOS</p>                                
                            </div>
                        </div>
                    </article>
                </Zoom>
            </section>

            <section className='sectionContactHome'>
                <p>¿Que esperas para contactarnos?</p>
                <Link to="/contacto" className='menuOption' onClick={() => { topRef.current.scrollIntoView(true) }}><button>Contactar</button></Link>
            </section>

            {/* <section>
                <h3 className='sectionBrands'>Nuestros proveedores</h3>
                <Brands />
            </section> */}
        </div>
    );
};

export default Home;