import { useEffect, useState } from 'react';
import { BrowserRouter as Switch,Route,Link} from "react-router-dom";
import Slide from 'react-reveal/Slide';
import './Home.css';
import Products from '../Products/Products';

const Home = () => {
    
    const [stateCategory , setStateCategory] = useState(0);
    const [classAnimation , setClassAnimation] = useState('');
    const [categoryImage , setCategoryImage] = useState();
    
    const getCategorysSpecifit = async () => {
        let categorysData = [];
        const response = await fetch('https://proveedoramys.com.co/api/getallcategoriasslider');
        const json = await response.json();
    
        json.forEach((element) => {
          categorysData.push({
            "urlImage": element.URL_IMG,
            "categoria": element.CATEGORIA,
            "codcategoria": element.CODCATEGORIA,
          });
        });
    
        setCategoryImage(categorysData);
      };
    
    useEffect(() => {
        getCategorysSpecifit();
    }, []);

    const backSlider = () =>{
        let url = categoryImage.shift();

        const categoryBack = {
            urlImage: url.urlImage,
            categoria: url.categoria,
            codcategoria: url.codcategoria,
        }
        setCategoryImage([...categoryImage, categoryBack]);
        showAnimation();
    }

    const nextSlider = () =>{
        let url = categoryImage.pop();

        const categoryNext = {
            urlImage: url.urlImage,
            categoria: url.categoria,
            codcategoria: url.codcategoria,
        }

        setCategoryImage([categoryNext, ...categoryImage]);
        showAnimation();
    }

    const showAnimation = () => {
        setClassAnimation('classAnimation');

        setTimeout(() => {
            setClassAnimation('');
        }, 500);
    }

    return (
        <>
        { categoryImage !== undefined &&
            <Slide left>
                <section className='sectionCarousel'>
                    <article className='slider'>
                        <img className='imageCarousel' src={categoryImage[stateCategory].urlImage} alt='carousel'></img>
                        <div className='efectBlur'>
                            <Link to={`/productos/:${categoryImage[stateCategory].codcategoria}`}>
                                <button className='btnCategoryHome'>{categoryImage[stateCategory].categoria}</button>
                            </Link>
                        </div>
                    </article>

                    <article className='slider'>
                        <img className='imageCarousel' src={categoryImage[stateCategory+1].urlImage} alt='carousel'></img>
                        <div className='efectBlur'>
                            <Link to={`/productos/:${categoryImage[stateCategory+1].codcategoria}`}>
                                <button className='btnCategoryHome'>{categoryImage[stateCategory+1].categoria}</button>
                            </Link>
                        </div>
                    </article>

                    <article className='slider'>
                        <img className={`imageCarousel ${classAnimation}`} src={categoryImage[stateCategory+2].urlImage} alt='carousel'></img>
                        <div className='efectBlur'>
                            <Link to={`/productos/:${categoryImage[stateCategory+2].codcategoria}`}>
                                <button className='btnCategoryHome'>{categoryImage[stateCategory+2].categoria}</button>
                            </Link>
                        </div>
                    </article>

                    <article className='slider'>
                        <img className='imageCarousel' src={categoryImage[stateCategory+3].urlImage} alt='carousel'></img>
                        <div className='efectBlur'>
                            <Link to={`/productos/:${categoryImage[stateCategory+3].codcategoria}`}>
                                <button className='btnCategoryHome'>{categoryImage[stateCategory+3].categoria}</button>
                            </Link>
                        </div>
                    </article>

                    <article className='slider'>
                        <img className='imageCarousel' src={categoryImage[stateCategory+4].urlImage} alt='carousel'></img>
                        <div className='efectBlur'>
                            <Link to={`/productos/:${categoryImage[stateCategory+4].codcategoria}`}>
                                <button className='btnCategoryHome'>{categoryImage[stateCategory+4].categoria}</button>
                            </Link>
                        </div>
                    </article>
                </section>
            </Slide>
        }
            <article className='btnsCarousel'>
                <button className='icon-left-open' onClick={() =>backSlider()}></button>
                <button className='icon-right-open' onClick={() => nextSlider()}></button>
            </article>
        </>
    );
};

export default Home;